import ApplicationController from './application_controller'

export default class extends ApplicationController {
    onSubmit(e) {
        e.preventDefault();
    }

    afterReflex() {
        const focusElement = this.element.querySelector('[autofocus]')
        if (focusElement) {
            focusElement.focus()
            const value = focusElement.value
            focusElement.value = ''
            focusElement.value = value
        }
    }
}