import ApplicationController from './application_controller'

export default class extends ApplicationController {
    driverToursPath = {}
    driverMarkers = {}
    map = null
    sortable = null
    infoWindow = null
    lineSymbol = null
    pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
    labelOriginFilled = null
    svgMarker = null

    connect() {
        super.connect();
        this.infoWindow = new window.google.maps.InfoWindow();
        this.lineSymbol = {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        };
        this.labelOriginFilled = new window.google.maps.Point(12, 9);
        this.svgMarker = {
            path: this.pinSVGFilled,
            anchor: new window.google.maps.Point(12, 17),
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "white",
            scale: 2,
            labelOrigin: this.labelOriginFilled,
        }
        this.sortable = new window.Draggable.Sortable(document.querySelectorAll('.driver-service-container'), {
            draggable: '.service'
        });
        this.sortable.on('sortable:stop', (e) => {
            this._onServiceDropped(e, this);
        });
        this.initMap()
    }

    saveRoutes() {
        let routes = []
        for (let driverId in window.driversTours) {
            if (driverId !== "0") {
                const [driverColor, tourStops] = window.driversTours[driverId];
                routes.push({
                    driver_id: parseInt(driverId,10),
                    services: tourStops.map(([position, priority, info, serviceId, completed, completeAddress, geolocalized]) => serviceId)
                })
            }
        }
        this.stimulate('OrganizeTourComponent#save_routes', routes);
    }

    initMap() {
        this.map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 13,
            center: window.mapTerritoryCenterCoordinates,
        });
        this._drawDriverTours()

    }

    toggleTourVisibility({params: {driverId}}) {
        this.driverToursPath[driverId].setMap(this.driverToursPath[driverId].map === null ? this.map : null);
        for (const serviceId in this.driverMarkers[driverId]) {
            const marker = this.driverMarkers[driverId][serviceId];
            marker.setMap(marker.map === null ? this.map : null);
        }
        document
            .querySelectorAll(".driver-service-container[data-driver-id='" + driverId + "'] .service")
            .forEach((el) => el.classList.toggle('hidden'));
    }

    _drawDriverTours() {
        for (const driverId in window.driversTours) {
            this._drawDriverServices(driverId);
            this._drawDriverTour(driverId);
        }
    }

    _drawDriverServices(driverId) {
        const [driverColor, tourStops] = window.driversTours[driverId];

        this.driverMarkers[driverId] = {};
        tourStops.forEach(([position, priority, info, serviceId, completed, completeAddress, geolocalized], index) => {
            this.svgMarker.fillColor = driverColor + (completed ? '80' : '');
            if (!geolocalized) {
                this.svgMarker.fillColor = "black";
            }
            var label = {
                text: (index + 1).toString(),
                color: "white",
                fontSize: "12px",
            };
            const marker = new window.google.maps.Marker({
                position,
                map: this.map,
                icon: this.svgMarker,
                label,
                optimized: false,
            });

            // Add a click listener for each marker, and set up the info window.
            marker.addListener("click", () => {
                this.infoWindow.close();
                this.infoWindow.setContent(info);
                this.infoWindow.open(marker.getMap(), marker);
            });
            this.driverMarkers[driverId][serviceId] = marker
        });
    }

    _drawDriverTour(driverId) {
        if (driverId === "0") {
            return
        }
        const [driverColor, tourStops] = window.driversTours[driverId];
        const tourPath = new window.google.maps.Polyline({
            path: this._buildDriverPath(tourStops),
            icons: [{
                icon: this.lineSymbol,
                offset: '100%',
                repeat: '10%'
            }],
            geodesic: true,
            strokeColor: driverColor,
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });
        tourPath.setMap(this.map);
        this.driverToursPath[driverId] = tourPath
    }

    _buildDriverPath(tourStops) {
        return tourStops.map(function ([coordinates]) {
            return coordinates
        })
    }

    _onServiceDropped({data: {oldContainer, newContainer, oldIndex, newIndex}}, that) {
        const oldDriverId = oldContainer.dataset.driverId;
        const newDriverId = newContainer.dataset.driverId;
        const serviceId = newContainer.children[newIndex].dataset.serviceId;
        const [_1, oldDriverServices] = window.driversTours[oldDriverId];
        const serviceToMove = oldDriverServices.splice(oldIndex, 1);
        const [newDriverColor, newDriverServices] = window.driversTours[newDriverId];
        newDriverServices.splice(newIndex, 0, serviceToMove[0]);
        const marker = that.driverMarkers[oldDriverId][serviceId];
        that.driverMarkers[oldDriverId][serviceId] = null;
        that._changeColorToMarker(marker, newDriverColor);
        that.driverMarkers[newDriverId][serviceId] = marker;
        if (oldDriverId > 0) {
            that.driverToursPath[oldDriverId].setPath(that._buildDriverPath(oldDriverServices));
        }
        if (newDriverId > 0) {
            that.driverToursPath[newDriverId].setPath(that._buildDriverPath(newDriverServices));
        }
    }

    openDriverMap({params: {driverId}}) {
        const [_1, services] = window.driversTours[driverId];
        const route = services.reduce((result, [_position, _priority, _info, _serviceId, _completed, completeAddress, _geolocalized]) => {
            return `${result}/${completeAddress}`;
        }, "");
        window.open(`https://www.google.it/maps/dir/${window.laundryAddress}${route}/${window.laundryAddress}`, "_blank");
    }

    _changeColorToMarker(marker, newColor) {
        marker.icon.fillColor = newColor;
        marker.setMap(null);
        marker.setMap(this.map);
    }
}