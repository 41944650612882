import ApplicationController from './application_controller'

export default class extends ApplicationController {
    onSubmit(e) {
        e.preventDefault();
    }

    changeToDate(e) {
        this.stimulate('CashOnDeliveriesComponent#change_to_date', e.currentTarget.value)
    }

    changeFromDate(e) {
        this.stimulate('CashOnDeliveriesComponent#change_from_date', e.currentTarget.value)
    }

}