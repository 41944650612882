import ApplicationController from './application_controller'
import * as ActiveStorage from "@rails/activestorage";
import Uploader from "./uploader";


export default class extends ApplicationController {
    static targets = [
        "barcode",
        "proofImageId",
        "takenPicture",
        "uploadProgressBar"
    ]

    async toggleBarcodeReader(e) {
        e.preventDefault();
        this.showBarcodeReader(this.didScanListener).catch((error) => {
            alert(JSON.stringify(error, null, 2));
        });
    }

    didScanListener = async (barcode) => {
        this.stimulate(`LoadVehicleComponent#read_barcode`, `McMc8H${barcode.data}`, this.proofImageIdTarget.value);
    }

    onSubmit(e) {
        e.preventDefault();
    }

    onChangeBarcode(e) {
        this.stimulate('LoadVehicleComponent#read_barcode', this.barcodeTarget.value, this.proofImageIdTarget.value);
        this.barcodeTarget.value = "";
    }

    onChangeBarcodeImage(e) {
        if (e.target.files && e.target.files.length) {
            const that = this;
            this.resizeImage(e.target.files[0], (blob, dataurl) => {
                that.takenPictureTarget.src = dataurl;
                ActiveStorage.start()
                const file = blob;
                file.name = e.target.files[0].name;
                const uploader = new Uploader(file, e.target.dataset.directUploadUrl)
                uploader.upload(that.uploadProgressBarTarget, function(blob) {
                    that.proofImageIdTarget.value = blob.signed_id;
                });
            });
        }
    }

    afterReflex(element, reflex, noop, reflexId) {
        this.showScannerFeedback(element);
        const focusElement = this.element.querySelector('[autofocus]');
        if (focusElement) {
            focusElement.focus();
        }
    }
}