import * as ActiveStorage from "@rails/activestorage";

export default class Uploader {
    constructor(file, url) {
        this.directUpload = new ActiveStorage.DirectUpload(file, url, this)
    }

    upload(uploadProgressBar, successCallback) {
        this.uploadProgressBar = uploadProgressBar;
        this.setProgressBar(0);
        this.directUpload.create((error, blob) => {
            if (error) {
                alert("upload fallito");
                alert(error);
            } else {
                successCallback(blob);
            }
        })
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress",
            event => this.directUploadDidProgress(event))
    }

    directUploadDidProgress(event) {
        let progress = ((event.loaded / event.total) * 100).toFixed(1);
        this.setProgressBar(progress)
    }

    setProgressBar(progress) {
        this.uploadProgressBar.style["width"] = progress + "%";
        this.uploadProgressBar.setAttribute("aria-valuenow", progress)
    }
}