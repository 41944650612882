import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import {
    Camera,
    CameraSwitchControl,
    configure,
    DataCaptureContext,
    DataCaptureView,
    FrameSourceState,
    RectangularViewfinder,
    RectangularViewfinderLineStyle,
    RectangularViewfinderStyle,
    TorchSwitchControl
} from "scandit-web-datacapture-core";
import {
    BarcodeCapture,
    barcodeCaptureLoader,
    BarcodeCaptureOverlay, BarcodeCaptureOverlayStyle,
    BarcodeCaptureSettings,
    Symbology
} from "scandit-web-datacapture-barcode";

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
    static didScanListener;

    connect() {
        StimulusReflex.register(this);
    }

    /* Application-wide lifecycle methods
     *
     * Use these methods to handle lifecycle concerns for the entire application.
     * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
     *
     * Arguments:
     *
     *   element - the element that triggered the reflex
     *             may be different than the Stimulus controller's this.element
     *
     *   reflex - the name of the reflex e.g. "Example#demo"
     *
     *   error/noop - the error message (for reflexError), otherwise null
     *
     *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
     */

    beforeReflex(element, reflex, noop, reflexId) {
        // this.benchmark = performance.now();
        // document.body.classList.add('wait')
    }

    reflexSuccess(element, reflex, noop, reflexId) {
    }

    reflexError(element, reflex, error, reflexId) {
    }

    afterReflex(element, reflex, noop, reflexId) {
        // console.log(reflex, `${(performance.now() - this.benchmark).toFixed(0)}ms`);
        // document.body.classList.remove('wait')
        console.log({element, reflex, noop, reflexId});
        this.highlight(element);
    }

    showBarcodeReader = async (didScanListener) => {
        this.emptyScannerFeedback();
        this.didScanListener = didScanListener;
        $('#scanner-modal').modal();
        $("#scanner-modal").off("hidden.bs.modal");
        $("#scanner-modal").on("hidden.bs.modal", function () {
            window.camera.switchToDesiredState(FrameSourceState.Standby);
        });
        if (window.barcodeScannerView) {
            window.barcodeCapture.listeners.clear();
            window.barcodeCapture.addListener({
                didScan: this.didScan,
            });
            const scannerFeedback = this.getScannerFeedback();
            scannerFeedback.textContent = "";
            const cameraState = window.camera.getCurrentState();
            if (cameraState === FrameSourceState.Off || cameraState === FrameSourceState.Standby) {
                await window.camera.switchToDesiredState(FrameSourceState.On)
            }
            await window.barcodeCapture.setEnabled(true);
            return;
        }
        window.barcodeScannerView = new DataCaptureView();
        let dataCaptureView = document.getElementById("data-capture-view");
        dataCaptureView.className = ""
        window.barcodeScannerView.connectToElement(dataCaptureView);
        window.barcodeScannerView.showProgressBar();

        await configure({
            licenseKey: window.SCANDIT_LICENCE_KEY,
            libraryLocation: new URL("https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/").toString(),
            moduleLoaders: [barcodeCaptureLoader()]
        });

        window.barcodeScannerView.setProgressBarPercentage(null);
        window.barcodeScannerView.setProgressBarMessage("Accessing Camera...");
        if (!window.dataCaptureContext) {
            window.dataCaptureContext = await DataCaptureContext.createWithOptions({deviceName: window.SCANDIT_DEVICE_NAME});
        }
        await window.barcodeScannerView.setContext(window.dataCaptureContext);
        window.camera = Camera.default;
        const cameraSettings = BarcodeCapture.recommendedCameraSettings;
        await window.camera.applySettings(cameraSettings);
        await window.dataCaptureContext.setFrameSource(window.camera);
        const settings = new BarcodeCaptureSettings();
        settings.enableSymbologies([
            Symbology.Code128,
        ]);
        window.barcodeCapture = await BarcodeCapture.forContext(window.dataCaptureContext, settings);
        await window.barcodeCapture.setEnabled(false);
        window.barcodeScannerView.addControl(new CameraSwitchControl());
        window.barcodeScannerView.addControl(new TorchSwitchControl());
        const barcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
            window.barcodeCapture,
            window.barcodeScannerView,
            BarcodeCaptureOverlayStyle.Frame
        );
        window.barcodeCapture.addListener({
            didScan: this.didScan,
        });
        const viewfinder = new RectangularViewfinder(
            RectangularViewfinderStyle.Square,
            RectangularViewfinderLineStyle.Light,
        );
        await barcodeCaptureOverlay.setViewfinder(viewfinder);
        await window.camera.switchToDesiredState(FrameSourceState.On);
        await window.barcodeCapture.setEnabled(true);
        window.barcodeScannerView.hideProgressBar();
    }

    didScan = async (barcodeCaptureMode, session) => {
        // Hide the viewfinder.
        // Disable the capture of barcodes until the user closes the displayed result.
        await window.barcodeCapture.setEnabled(false);
        const barcode = session.newlyRecognizedBarcodes[0];
        this.emptyScannerFeedback();
        document.querySelector("#scanner-loader").classList.remove("hidden");
        await this.didScanListener(barcode);
    }

    showScannerFeedback = async (element) => {
        this.emptyScannerFeedback();
        await window.barcodeCapture.setEnabled(true);
        const elementFeedback = element.querySelector(".alert");
        if (elementFeedback) {
            this.getScannerFeedback().append(elementFeedback.cloneNode(true));
        }
    }

    emptyScannerFeedback = () => {
        this.getScannerFeedback().textContent = "";
        document.querySelector("#scanner-loader").classList.add("hidden");
    }

    getScannerFeedback = () => {
        return document.querySelector('#scanner-feedback');
    }

    resizeImage = (imageFile, resizedCallback) => {
        const reader = new FileReader();
        reader.onload = function (readerOnloadEvent) {
            const img = document.createElement("img");
            img.onload = function (imageOnloadEvent) {
                // Dynamically create a canvas element
                const canvas = document.createElement("canvas");
                const MAX_WIDTH = 640;
                const MAX_HEIGHT = 640;
                let width = img.width;
                let height = img.height;

                // Change the resizing logic
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height = height * (MAX_WIDTH / width);
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width = width * (MAX_HEIGHT / height);
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");

                // Actual resizing
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob(blob => resizedCallback(blob, canvas.toDataURL(imageFile.type)));
            }
            img.src = readerOnloadEvent.target.result;
        }
        reader.readAsDataURL(imageFile);
    }

    highlight = (element) => {
        element.classList.add('highlight-change');
        setTimeout(() => {
            element.classList.remove('highlight-change');
        }, 200);
    }
}
