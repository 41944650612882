import ApplicationController from './application_controller'
import * as ActiveStorage from "@rails/activestorage";
import Uploader from "./uploader";


export default class extends ApplicationController {
    static targets = [
        "barcode"
    ]

    async toggleBarcodeReader(e) {
        e.preventDefault();
        await this.showBarcodeReader(this.didScanListener);
    }

    didScanListener = async (barcode) => {
        this.stimulate(`SearchOrderPackageEventsComponent#search`, barcode.data);
    }

    onSubmit(e) {
        e.preventDefault();
    }

    onChangeBarcode(e) {
        this.stimulate('SearchOrderPackageEventsComponent#search', this.barcodeTarget.value);
        this.barcodeTarget.value = "";
    }

    afterReflex(element, reflex, noop, reflexId) {
        this.showScannerFeedback(element);
        const focusElement = this.element.querySelector('[autofocus]');
        if (focusElement) {
            focusElement.focus();
        }
    }
}