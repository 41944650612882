import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = [
        "barcode",
        "prefix",
    ]

    async toggleBarcodeReader(e) {
        e.preventDefault();
        await this.showBarcodeReader(this.didScanListener);
    }

    didScanListener = async (barcode) => {
        this.stimulate(`DriverTourSecurityLocksComponent#add_security_lock`, this.prefixTarget.value, `McMc8H${barcode.data}`);
    }

    onSubmit(e) {
        e.preventDefault();
    }

    onChangeSecurityLockCode(e) {
        this.stimulate('DriverTourSecurityLocksComponent#add_security_lock', this.prefixTarget.value, this.barcodeTarget.value);
        this.barcodeTarget.value = "";
    }

    afterReflex = (element, reflex, noop, reflexId) => {
        this.showScannerFeedback(element);
        const focusElement = this.element.querySelector('[autofocus]');
        if (focusElement) {
            focusElement.focus();
        }
    }
}