import ApplicationController from './application_controller'
import SignaturePad from "signature_pad";
import trimCanvas from "trim-canvas";
import * as ActiveStorage from "@rails/activestorage";
import Uploader from "./uploader";

export default class extends ApplicationController {
    static signaturePad;
    static targets = ["signatureCanvas",
        "signatureData",
        "skipServiceReason",
        "skipServiceReasonCode",
        "barcode",
        "proofImageId",
        "takenPicture",
        "uploadProgressBar"
    ]

    async toggleBarcodeReader(e) {
        e.preventDefault();
        await this.showBarcodeReader(this.didScanListener);
    }

    didScanListener = async (barcode) => {
        this.stimulate('DriverTourServiceComponent#read_barcode', `McMc8H${barcode.data}`, this.proofImageIdTarget.value);
    }

    onSubmit(e) {
        e.preventDefault();
    }

    signature() {
        const windowWidth = $(window).width()
        const windowHeight = $(window).height()

        let width;
        let height;
        if (windowWidth > windowHeight) {
            width = windowWidth
        } else {
            width = windowHeight
        }
        if (windowWidth > windowHeight) {
            height = windowHeight
        } else {
            height = windowWidth
        }
        width = Math.min(width, 500)
        height = Math.min(height, 240)

        this.signatureCanvasTarget.setAttribute('width', width)
        this.signatureCanvasTarget.setAttribute('height', height)

        this.signaturePad = new SignaturePad(this.signatureCanvasTarget)
        this.signaturePad.on()
    }

    clear() {
        this.signaturePad.clear()
    }

    save() {
        $("#signature-pad").modal("hide")
        $('.signature-preview').attr('src', this.signaturePad.toDataURL()).show()
        trimCanvas(this.signatureCanvasTarget)
        this.signatureDataTarget.value = this.signaturePad.toDataURL()
        this.stimulate('DriverTourServiceComponent#save_signature', this.signaturePad.toDataURL())
    }

    toggleSkipReasons() {
        this.stimulate('DriverTourServiceComponent#toggle_skip_reason')
    }

    onChangeSkipReasonCode() {
        const reasonCode = this.skipServiceReasonCodeTarget.value
        if (reasonCode !== 'other') {
            this.skipServiceReasonTarget.value = ''
            this.skipServiceReasonTarget.classList.remove('is-invalid')
        }
        this.stimulate('DriverTourServiceComponent#change_skip_reason_code', reasonCode)
    }

    showModalSkipService() {
        if (this.skipServiceReasonTarget.value === '' && this.skipServiceReasonCodeTarget.value === 'other') {
            this.skipServiceReasonTarget.classList.add('is-invalid');
            return;
        }
        if (this.skipServiceReasonCodeTarget.value === '') {
            this.skipServiceReasonCodeTarget.classList.add('is-invalid');
            return;
        }
        this.skipServiceReasonTarget.classList.remove('is-invalid')
        let logisticConfirmations = this.element.querySelectorAll('.logistic-confirmations')[0];
        $(logisticConfirmations).modal()
    }

    skipService() {
        let logisticConfirmations = this.element.querySelectorAll('.logistic-confirmations')[0];
        $(logisticConfirmations).modal('hide');
        this.stimulate('DriverTourServiceComponent#skip_service', this.skipServiceReasonCodeTarget.value, this.skipServiceReasonTarget.value);
    }


    togglePayWithPos() {
        this.element.querySelector('.btn-pay-with-pos').classList.remove('hidden')
        this.element.querySelector('.btn-open-sumup').classList.add('hidden')
    }

    onChangeBarcode(e) {
        this.stimulate('DriverTourServiceComponent#read_barcode', this.barcodeTarget.value, this.proofImageIdTarget.value);
        this.barcodeTarget.value = "";
    }

    onChangeBarcodeImage(e) {
        if (e.target.files && e.target.files.length) {
            const that = this;
            this.resizeImage(e.target.files[0], (blob, dataurl) => {
                that.takenPictureTarget.src = dataurl;
                ActiveStorage.start()
                const file = blob;
                file.name = e.target.files[0].name;
                const uploader = new Uploader(file, e.target.dataset.directUploadUrl)
                uploader.upload(that.uploadProgressBarTarget, function(blob) {
                    that.proofImageIdTarget.value = blob.signed_id;
                });
            });
        }
    }

    afterReflex(element, reflex, noop, reflexId) {
        this.showScannerFeedback(element);
        const focusElement = this.element.querySelector('[autofocus]')
        if (focusElement) {
            focusElement.focus()
            focusElement.value = ''
        }
    }
}